import { ITenantRequestManager } from "@@services/apiaccessprovider.definition";
import { ITenantEvent } from "../data/tenantevent";


function datasource() {
    return import(/* webpackChunkName: "mod-apidatasource" */ "../../../api/front/datasource");
}

function tenantDataSource<T>(requestManager: ITenantRequestManager, customerId: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tenantId: string, endpoint, noCache: boolean) {
    return datasource().then((mod) => {
        return mod.getFrontDataSource<T>(requestManager, customerId + '/' + tenantId + '/' + endpoint, noCache);
    });
}

export function tenantEventsDataSource(requestManager: ITenantRequestManager, customerId: string,
    tenantId: string, noCache: boolean) {
    return tenantDataSource<ITenantEvent>(requestManager, customerId, tenantId, 'authtenantevent', noCache);
}
