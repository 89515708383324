import { registerBloc } from '../../components/dynamicpage/blocscatalog';
import { BlocTeaserEvents, blocTeaserEventsData } from './blocs/teaservents/bloc.teaserevents';

export { initEventInfoProvider } from '../../client/eventinfo';
export { RootWebsiteShell } from './shell/rootwebsiteshell';

registerBloc("teaserevents", {
    component: BlocTeaserEvents,
    datainit: blocTeaserEventsData
});

let PreviewWebsiteShellComponent = null;

if (InWinkPreview) {
    PreviewWebsiteShellComponent = require("./shell/rootwebsiteshell.preview").PreviewWebsiteShell;
}

export const PreviewWebsiteShell = PreviewWebsiteShellComponent;
