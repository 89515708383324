import * as React from 'react';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { States } from '@@services/services';
import { connectRealtimeHost, realtimeHost, RealtimeManagerContext, IRealtimeManager } from '@@services/realtimeservice';

export interface IWebsiteRealtimeProps {
    rootwebsite?: States.IRootWebsiteState;
    user?: States.IAppUserState;
    children?: React.ReactNode;
}

export interface IWebsiteRealtimeState {
    manager: Promise<IRealtimeManager>;
}

@connectwith((state: States.IAppState) => {
    return {
        rootwebsite: state.rootwebsite,
        user: state.user
    };
})
export class WebsiteRealtime extends React.Component<IWebsiteRealtimeProps, IWebsiteRealtimeState> {
    constructor(props : IWebsiteRealtimeProps) {
        super(props);

        this.state = {
            manager: this.getCurrentManager(props)
        };
    }

    componentDidUpdate(prevprops: IWebsiteRealtimeProps) {
        const eventChanged = prevprops.rootwebsite.websiteid !== this.props.rootwebsite.websiteid;
        const notInitialized = !this.state.manager && this.props.rootwebsite.detail;

        if (eventChanged || notInitialized) {
            this.setState({
                manager: this.getCurrentManager(this.props)
            });
        }

        if (this.props.user.currentUser?.detail !== prevprops.user.currentUser?.detail) {
            this.state.manager.then((mgr) => mgr.registerUser(this.props.rootwebsite.websiteid));
        }
    }

    getCurrentManager(props: IWebsiteRealtimeProps) {
        if (__SERVERSIDE__) {
            return null;
        }

        if (props.rootwebsite && props.rootwebsite.detail) {
            let host = props.rootwebsite.detail.configuration?.realtime?.default;
            if (!host) {
                host = realtimeHost();
            }
            const manager = connectRealtimeHost(host);

            if (InWinkPreview || props.user.currentUser?.detail) {
                manager.then((mgr) => {
                    mgr.init();
                    mgr.registerEvent(props.rootwebsite.websiteid);
                });
            }
            return manager;
        }
    }

    render() {
        return <RealtimeManagerContext.Provider value={this.state.manager}>
            {this.props.children}
        </RealtimeManagerContext.Provider>;
    }
}
