import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { getBlocTemplate } from '@@services/itemshelpers';
import { IDynamicBlocProps } from '@@pages/common';
import { TeaserItems } from '@@pages/teaseritems';
import { SortAndFilterItems } from '@@pages/teaseritems.props';
import { States } from '@@services/services';
import { appSplashActions } from '@@services/splashservice';
import { IInwinkStore, wrapReduxStore } from '@@store/index';
import { setEventInfoFor } from '../../../../client/eventinfo';
import { tenantEventsDataSource } from '../../api/tenant';
import { Loader } from '@inwink/loader';

import './index.less';
export interface IBlocTeaserEventsState {
    events: any[];
    itemtemplate: VisualTheme.IItemTemplate;
    loading: boolean;
}

export function blocTeaserEventsData(page: States.ICurrentPageState, data: IBlocTeaserEventsState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bloc: VisualTheme.IBlocContentTemplateBase, state: States.IAppState, dispatch: (action) => void) {
    const blocdata = data;
    const itemtemplate = getBlocTemplate(state.rootwebsite.data, bloc);
    blocdata.itemtemplate = itemtemplate;
    const websiteEvents = [...state.rootwebsite.data.events.data];
    blocdata.events = SortAndFilterItems(state.user, state.i18n, websiteEvents, bloc.properties, false, page.context.entity);
    if (!bloc.properties.sort || !bloc.properties.sort.propName) {
        blocdata.events = blocdata.events.sort((a, b) => {
            if (a.event.startDate > b.event.startDate) {
                return -1;
            }
            if (a.event.startDate < b.event.startDate) {
                return 1;
            }
            return 0;
        });
    }
    blocdata.itemtemplate = itemtemplate;
}

interface IBlocTeaserEventsProps extends IDynamicBlocProps<IBlocTeaserEventsState> {
    // eventActions: typeof eventActions;
    appSplashActions: typeof appSplashActions;
}

class BlocTeaserEventsComponent extends React.PureComponent<IBlocTeaserEventsProps, any> {
    constructor(props: IBlocTeaserEventsProps) {
        super(props);
        this.state = {
            entitytemplate: this.props.rootwebsite?.data?.fieldtemplates?.data
                .find((f) => f.entityName.toLowerCase() === "customerevent"),
            events: [],
            isLoading: true,
            isOldWebsite: this.props.rootwebsite.customerid === this.props.rootwebsite.tenantid
        };
    }

    getEvents = () => {
        const rootWebsite = this.props.rootwebsite;
        // SI c'est un ancien site et que donc il n'est pas lié a un tenant,
        // alors on garde le comportement d'avant
        if (this.state.isOldWebsite) {
            this.setState({events: this.props.blocState?.events, isLoading: false});
        } else {
            tenantEventsDataSource(
                rootWebsite.requestManager.apiFront,
                rootWebsite.customerid,
                rootWebsite.tenantid,
                false
            ).then((ds) => {
                return ds.query({
                    order: [{ by: "startDate", desc: true }]
                }).then((events) => {
                    let websiteEvents = events.data.map((e) => ({
                        id: e?.id,
                        event: e
                    }));
                    websiteEvents = SortAndFilterItems(
                        this.props.user,
                        this.props.i18n,
                        websiteEvents,
                        this.props.template?.properties,
                        false,
                        this.props.page.context.entity);
                    this.setState({
                        isLoading: false,
                        events: websiteEvents
                    });
                }, () => {
                    this.setState({isLoading: false});
                });
            }, () => {
                this.setState({isLoading: false});
            });
        }
    };

    componentDidMount() {
        this.getEvents();
    }

    getEventUrl = (websiteevent) => {
        return websiteevent?.event?.computedUrl;
    };

    itemClicked = (websiteevent) => {
        const event = websiteevent?.event;
        setEventInfoFor(event.eventKey, {
            eventId: event.id,
            authTenantId: event.tenantId,
            customerId: event.customerId
        });
        import(/* webpackChunkName: "mod-eventreducer" */ '@@event/services/eventservice').then((mod) => {
            ((this.props as any).store as IInwinkStore).injectReducer('event', mod.eventReducer);
        });
    };

    componentDidUpdate(prevProps: IBlocTeaserEventsProps) {
        if (prevProps?.template !== this.props.template) {
            this.getEvents();
        }
    }

    render() {
        let link;
        let events;
        if (InWinkPreview || this.state.isOldWebsite) {
            events = this.state.events;
        } else {
            events = this.state.events.filter((e) => e.event?.isPublished);
        }
        // On force ici le _blank #48502
        const itemTemplate = Object.assign({}, this.props.blocState?.itemtemplate
            || (this.props.template.properties && this.props.template.properties.template));
        if (itemTemplate?.template && InWinkPreview) {
            itemTemplate.template.target = "_blank";
        }
        const onClick = this.itemClicked;

        if (this.props.template && this.props.template.properties && !this.props.template.properties.disableNavigation) {
            link = this.getEventUrl;
        }

        if (!this.props.blocState) return null;

        if (this.state.isLoading) {
            return <div className="teaserevents-loader">
                <Loader />
            </div>;
        }

        return <TeaserItems
            {...this.props}
            entitySchema={this.state.entitytemplate}
            defaultItemsAlign="left"
            defaultItemsLayout="col2"
            templateProperties={this.props.template.properties.templateProperties}
            allowEmpty={this.props.template && this.props.template.properties && this.props.template.properties.allowEmpty}
            emptyMessage={this.props.template && this.props.template.properties && this.props.template.properties.emptyMessage}
            className="teaserevents"
            minCarouselItems={this.props.template?.properties?.minCarouselItems || null}
            linkto={link}
            onItemClick={onClick}
            items={events}
            itemClassName="event-item"
            itemtemplate={itemTemplate}
        />;
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        appSplashActions: bindActionCreators(appSplashActions, dispatch)
    };
}

export const BlocTeaserEvents: new (any)
=> React.Component<any, any> = connect(
    mapStateToProps,
    mapDispatchToProps
)(wrapReduxStore(BlocTeaserEventsComponent) as any) as any;
