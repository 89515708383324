/* eslint-disable react/no-unused-state */
import { Entities } from '@inwink/entities/entities';
import { logging } from '@inwink/logging';
import { VisualConfiguration } from '@inwink/visualconfiguration';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18NResourcesContext } from '@inwink/i18n/resourcecontext';
import { CookieTracking } from '@@components/cookiebar';
import { actions as i18nActions, IBestLanguage } from '@@services/i18nservice';
import { actions as pageActions } from '@@services/pageservice';
import { realtimeActions } from '@@services/realtimeservice';
import { wrapReduxStore, IInwinkStore } from '@@store/index';
import { rootWebsiteActionsModule } from '@@routes/appmodules';
import { States } from '@@services/services';
import { parse } from '@inwink/utils/querystring';
import { communityMailTracking, eventMailTracking, tenantMailTracking } from '@@api/front/mailtracking';
import { isFromServer } from '@@pages/helpers';
import { GuidPattern } from '@@services/index';

const logger = logging.getLogger("Data");

export interface IRootWebsiteDataProps {
    className?: string;
    tinyurl?: string;
    customerid?: string;
    websiteid?: string;
    event?: States.IEventState;
    community?: States.ICommunityState;
    rootwebsite?: States.IRootWebsiteState;
    user?: States.IAppUserState;
    i18n?: States.i18nState;
    visualConfiguration: Entities.IVisualConfigurationTemplate;
    websiteData?: States.IRootWebsiteDataStore;
    realtimeActions? : typeof realtimeActions;
    pageActions?: typeof pageActions;
    i18nActions?: typeof i18nActions;
    location: States.ILocation;
    match: States.ILocationMatch;
    store?: IInwinkStore;
    children?: React.ReactNode;
}

export interface IRootWebsiteDataState {
    loading?: boolean;
    sync?: boolean;
    forceSync?: boolean;
    lastSyncRequest : Date;
    syncError?:boolean;
    startFromServer: boolean;
}

class RootWebsiteDataComponent extends React.Component<IRootWebsiteDataProps, IRootWebsiteDataState> {
    container = React.createRef<HTMLDivElement>();

    constructor(props: IRootWebsiteDataProps) {
        super(props);

        const isFromSSR = isFromServer();
        this.state = {
            loading: false,
            sync: false,
            forceSync: false,
            lastSyncRequest: null,
            startFromServer: isFromSSR
        };

        if (!isFromSSR || !props.rootwebsite.hasCurrentEvent) {
            if (!isFromSSR) {
                this.trackMail();
            }
            rootWebsiteActionsModule().then((mod) => {
                mod.websiteBootstrapActions.initWebsite(
                    isFromSSR, props.location, props.match
                )(this.props.store.dispatch, this.props.store.getState);
            });
        } else {
            this.trackMail();
        }
    }

    trackMail() {
        if (this.props.location.search && this.props.location.search.indexOf('iw_mailid=') > -1) {
            const args = parse(this.props.location.search);
            const iwmailid = args?.iw_mailid;
            if (iwmailid && GuidPattern.test(iwmailid)) {
                if (this.props.event?.requestManagers?.apiFront
                    && this.props.event?.eventid) {
                    // email tracking event
                    eventMailTracking(
                        this.props.event?.requestManagers?.apiFront,
                        this.props.event?.eventid,
                        iwmailid
                    );
                } else if (this.props.community?.requestManagers?.apiFront
                    && this.props.community?.communityid) {
                    // email tracking community
                    communityMailTracking(
                        this.props.community?.requestManagers?.apiFront,
                        this.props.community?.communityid,
                        iwmailid
                    );
                } else if (this.props.rootwebsite?.requestManager?.apiFront
                    && this.props.rootwebsite?.detail?.authTenantId) {
                    // email tracking rootwebsite
                    tenantMailTracking(
                        this.props.rootwebsite?.requestManager?.apiFront,
                        this.props.rootwebsite?.detail?.authTenantId,
                        iwmailid
                    );
                }
            }
        }
    }

    syncCompleted(hasError?: boolean) {
        if (hasError) {
            logger.error('error syncing data, not blocking anyway...');
            this.setState({
                syncError: true
            });
        } else {
            this.setState({
                sync: false
            });
        }
    }

    componentDidMount() {
        if (this.state.startFromServer && this.props.rootwebsite.hasCurrentEvent) {
            setTimeout(() => {
                rootWebsiteActionsModule().then((mod) => {
                    mod.websiteBootstrapActions.initWebsite(
                        this.state.startFromServer, this.props.location, this.props.match
                    )(this.props.store.dispatch, this.props.store.getState);
                });
            }, 1000);
        }
    }

    componentDidUpdate() {
        if (this.props.match?.params?.lngcode && this.props.match.params.lngcode !== this.props.i18n.currentLanguageCode) {
            if (this.props.i18n.supported && this.props.i18n.supported.indexOf(this.props.match.params.lngcode) >= 0) {
                const lng = ((this.props.i18nActions
                    .determineBestLanguage(this.props.match.params.lngcode)) as any) as IBestLanguage;
                this.props.i18nActions.loadLanguage(lng.language, lng.dateDisplayLanguage);
            }
        }
    }

    render() {
        let content;
        const trads = this.props.rootwebsite.data
            && this.props.rootwebsite.data.templates
            && this.props.rootwebsite.data.templates.data.filter((t) => t.contentType === "config" && t.tinyUrl === "trads")[0];

        const shouldRenderContent = (this.props.rootwebsite.detail || this.props.rootwebsite.hasCurrentEvent)
            && ((!this.state.loading) || isFromServer());

        if (shouldRenderContent) {
            if (this.props.rootwebsite.hasCurrentEvent) {
                return this.props.children;
            }

            content = this.props.children;
        }

        const cookiebarCfg = this.props.rootwebsite?.detail?.configuration?.cookiesWarning;
        const trackingCfg = this.props.rootwebsite?.detail?.configuration?.tracking;

        return <I18NResourcesContext resources={trads && trads.config && trads.config.labels}>
            <CookieTracking
                i18n={this.props.i18n}
                cookieid={this.props.rootwebsite && this.props.rootwebsite.detail && this.props.rootwebsite.detail.id}
                eventId={null}
                websiteId={this.props.rootwebsite && this.props.rootwebsite.detail && this.props.rootwebsite.detail.id}
                config={cookiebarCfg}
                tracking={trackingCfg}
            >
                { (cookiebar, contentClassName) => {
                    const wid = this.props.rootwebsite.websiteid;
                    const classNames = `app-shell rootwebsite rootwebsite-${wid} ${contentClassName || ""}`;
                    if (this.container?.current) {
                        if (classNames !== this.container.current.className) {
                            this.container.current.className = classNames;
                        }
                    }
                    return <div
                        ref={this.container}
                        className={classNames}
                    >
                        <div className={"app-shell-data " + (this.props.className || "")}>
                            <VisualConfiguration
                                visualConfiguration={this.props.visualConfiguration}
                                prefix={".rootwebsite-"
                                    + (this.props.rootwebsite.detail ? this.props.rootwebsite.detail.id : "") + " "}
                            />
                            {content}
                        </div>
                        {cookiebar}
                    </div>;
                }}
            </CookieTracking>
        </I18NResourcesContext>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        event: state.event,
        community: state.community,
    };
}

function mapRootWebsiteDataDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        i18nActions: bindActionCreators(i18nActions, dispatch),
        realtimeActions: bindActionCreators(realtimeActions, dispatch)
    };
}

export const RootWebsiteData: new (any) => React.Component<IRootWebsiteDataProps, any> = connect(
    mapStateToProps,
    mapRootWebsiteDataDispatchToProps
)(wrapReduxStore(RootWebsiteDataComponent) as any) as any;
