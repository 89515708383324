/* eslint-disable max-classes-per-file */
import { VisualConfigurationContextProvider } from '@inwink/visualconfiguration';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { logging } from '@inwink/logging';
import { Entities } from '@inwink/entities/entities';
import { I18NResourcesContext } from '@inwink/i18n/resourcecontext';
import { AppHeader } from '@@components/appheader';
import { AppMenu } from '@@components/appmenu';
import { ContentInjection } from '@@components/contentinjection';
import { ModalThemeContext } from '@@components/modalthemecontext';
import { PopoverDisplayContext, IPopoverDisplayOptionsContext } from '@inwink/modals/popoverdisplaycontext';
import {
    getPageFooter, getPageHeader, getTrads, getVisualConfiguration, getWebmaster
} from '@@data/templates';
import type { IDefaultEventPageProps } from '@@data/visualtheme';
import { metadataMenuActions } from '@@services/appmetadataactions/menu';
import { actions as i18nActions } from '@@services/i18nservice';
import { States } from '@@services/services';
import { UrlStateContext } from '@@components/urlstatecontext';
import { RootWebsiteData } from './rootwebsitedata';
import { AppShellWebsiteRoutes } from './rootwebsiteshell.routes';
import { WebsiteRealtime } from '../components/websiterealtime';
import type { IRootWebsiteShellProps, IAppShellWebsiteProps } from "./rootwebsiteshell.props";
import { hasItemsInMenu } from '@@modules/helpers';

const uilogger = logging.getLogger("UI.RootwebsiteShell");

class RootWebsiteShellComponent extends React.Component<IRootWebsiteShellProps, any> {
    constructor(props: IRootWebsiteShellProps) {
        super(props);
        let tracking;
        if (props.tracking && props.tracking.trackers && props.tracking.trackers.length) {
            tracking = {
                scripts: props.tracking.trackers
                    .filter((t) => t.websiteId === this.props.rootwebsite.websiteid)
                    .map((t) => t.script).filter((t) => !!t)
            };
        }

        uilogger.verbose("Init rootwebsite shell");

        const visualConf = this.props.rootwebsite.data ? getVisualConfiguration(this.props.rootwebsite.data) : null;
        this.state = {
            visualConfiguration: visualConf,
            webmaster: this.props.rootwebsite.data ? getWebmaster(this.props.rootwebsite.data) : null,
            pageheader: this.props.rootwebsite.data ? getPageHeader(this.props.rootwebsite.data) : null,
            pagefooter: this.props.rootwebsite.data ? getPageFooter(this.props.rootwebsite.data) : null,
            websitetrads: this.props.rootwebsite.data ? getTrads(this.props.rootwebsite.data) : null,
            tracking: tracking,
            popoverOptions: this.getPopoverOptions(visualConf)
        };
    }

    componentDidMount(): void {
        const tmp = (global as any);
        if (typeof tmp.inwinkWebsiteBootstrap !== "undefined") {
            tmp.inwinkWebsiteBootstrap = undefined;
        }
    }

    getPopoverOptions(visualConfiguration: Entities.IVisualConfigurationTemplate) {
        const res: IPopoverDisplayOptionsContext = {
            modal: {
                containerClassName: "rootwebsite-" + this.props.rootwebsite.websiteid,
                modalClassName: "bloctheme"
                    + (visualConfiguration?.global?.modalTheme ? " bloctheme-" + visualConfiguration.global.modalTheme : "")
            },
            popover: {
                containerClassName: "rootwebsite-" + this.props.rootwebsite.websiteid,
                modalClassName: "bloctheme"
                    + (visualConfiguration?.global?.popoverTheme ? " bloctheme-" + visualConfiguration.global.popoverTheme : "")
            },
            sidemodal: {
                containerClassName: "rootwebsite-" + this.props.rootwebsite.websiteid,
                modalClassName: "bloctheme"
                    + (visualConfiguration?.global?.modalTheme ? " bloctheme-" + visualConfiguration.global.modalTheme : "")
            }
        };
        return res;
    }

    componentDidUpdate(prevProps: IRootWebsiteShellProps, prevstate) {
        let patch = null;
        if (this.props.tracking && prevProps.tracking !== this.props.tracking
            && this.props.tracking.trackers && this.props.tracking.trackers.length) {
            patch = patch || {};
            patch.tracking = {
                scripts: this.props.tracking.trackers
                    .filter((t) => t.websiteId === this.props.rootwebsite.websiteid)
                    .map((t) => t.script).filter((t) => !!t)
            };
        }

        if (this.props.rootwebsite !== prevProps.rootwebsite
            || !this.state.visualConfiguration || !this.state.webmaster || !this.state.pageheader) {
            const visualconfig = getVisualConfiguration(prevProps.rootwebsite.data);
            if (visualconfig && visualconfig !== prevstate.visualConfiguration) {
                patch = patch || {};
                patch.visualConfiguration = visualconfig;
                patch.popoverOptions = this.getPopoverOptions(visualconfig);
            }

            const webmaster = getWebmaster(prevProps.rootwebsite.data);
            if (webmaster && webmaster !== prevstate.webmaster) {
                patch = patch || {};
                patch.webmaster = webmaster;
            }

            const pageheader = getPageHeader(prevProps.rootwebsite.data);
            if (pageheader && pageheader !== prevstate.pageheader) {
                patch = patch || {};
                patch.pageheader = pageheader;
            }

            const pagefooter = getPageFooter(prevProps.rootwebsite.data);
            if (pagefooter && pagefooter !== prevstate.pagefooter) {
                patch = patch || {};
                patch.pagefooter = pagefooter;
            }

            const websitetrads = getTrads(prevProps.rootwebsite.data);
            if (websitetrads && websitetrads !== prevstate.websitetrads) {
                patch = patch || {};
                patch.websitetrads = websitetrads;
            }
        }

        if (patch) {
            uilogger.verbose("rootwebsite shell updated");
            this.setState(patch);
        }
    }

    render() {
        const isServerSide = __SERVERSIDE__;
        uilogger.verbose("render rootwebsite shell");
        
        return <WebsiteRealtime>
            <PopoverDisplayContext.Provider value={this.state.popoverOptions}>
                <UrlStateContext baseUrl={this.props.match?.url} i18n={this.props.i18n}>
                    <VisualConfigurationContextProvider visualConfiguration={this.state.visualConfiguration}>
                        <ModalThemeContext
                            visualConfiguration={this.state.visualConfiguration}
                            prefix={"rootwebsite-" + (this.props.rootwebsite.detail && this.props.rootwebsite.detail.id)}
                        >
                            <I18NResourcesContext resources={this.state.websitetrads ? this.state.websitetrads.labels : null}>
                                <RootWebsiteData
                                    visualConfiguration={this.state.visualConfiguration}
                                    user={this.props.user}
                                    i18n={this.props.i18n}
                                    rootwebsite={this.props.rootwebsite}
                                    customerid={this.props.rootwebsite.customerid}
                                    websiteid={this.props.rootwebsite.websiteid}
                                    tinyurl={this.props.match && this.props.match.params.eventtinyurl}
                                    location={this.props.location}
                                    match={this.props.match}
                                >
                                    <Helmet />
                                    <AppShellWebsite
                                        {...this.props}
                                        webmasterConfiguration={this.state.webmaster}
                                        visualConfiguration={this.state.visualConfiguration}
                                        content={this.props.children}
                                        visualstate={this.props.visualstate}
                                        pagemenu={this.state.pagemenu}
                                        pageheader={this.state.pageheader}
                                        pagefooter={this.state.pagefooter}
                                    />
                                    <ContentInjection
                                        id="webmaster"
                                        inject={this.state.webmaster?.afterBody}
                                        serverSide={isServerSide} />
                                    <ContentInjection
                                        id="tracking"
                                        inject={this.state.tracking}
                                        serverSide={isServerSide} />
                                </RootWebsiteData>
                            </I18NResourcesContext>
                        </ModalThemeContext>
                    </VisualConfigurationContextProvider>
                </UrlStateContext>
            </PopoverDisplayContext.Provider>
        </WebsiteRealtime>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        // event: state.event,
        rootwebsite: state.rootwebsite,
        user: state.user,
        page: state.pages.currentPage,
        i18n: state.i18n,
        // appMetaData: state.appMetaData,
        tracking: state.tracking
    };
}

function mapDispatchToProps(dispatch) {
    return {
        metadataMenuActions: bindActionCreators(metadataMenuActions, dispatch),
        i18nActions: bindActionCreators(i18nActions, dispatch)
    };
}

export const RootWebsiteShell: React.ComponentClass<IRootWebsiteShellProps> = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RootWebsiteShellComponent as any) as any) as any;

export default RootWebsiteShell;

interface IIAppShellWebsiteState {
    datacontext: Entities.IPageDataContext;
}

class AppShellWebsite extends React.Component<IAppShellWebsiteProps, IIAppShellWebsiteState> {
    pages: any;

    constructor(props: IAppShellWebsiteProps) {
        super(props);

        this.state = {
            datacontext: this.getDataContext(props)
        };
    }

    getDataContext(props: IAppShellWebsiteProps) : Entities.IPageDataContext {
        const res: Entities.IPageDataContext = {
            communitystate: null,
            event: null, // props.event?.detail,
            eventstate: null, // props.event,
            userstate: props.user,
            user: props.user.currentUser ? props.user.currentUser.detail : null,
            i18nstate: props.i18n,
            fieldtemplate: null
        };

        return res;
    }

    componentDidUpdate(prevprops) {
        if (this.props.rootwebsite !== prevprops.rootwebsite || this.props.i18n !== prevprops.i18n) {
            this.setState({
                datacontext: this.getDataContext(this.props)
            });
        }
    }

    toggleMenu = () => {
        this.props.metadataMenuActions.toggleMenu();
    };

    render() {
        // const key = (this.props.location && this.props.location.pathname) || "ukn";
        const pageArgs: IDefaultEventPageProps = {
            // key: key,
            location: this.props.location,
            event: null,
            rootwebsite: this.props.rootwebsite,
            user: this.props.user,
            i18n: this.props.i18n,
            webmasterConfiguration: this.props.webmasterConfiguration,
            visualConfiguration: this.props.visualConfiguration,
            page: this.props.page,
            pagefooter: this.props.pagefooter,
            visualstate: this.props.visualstate,
            match: this.props.match
        };
        // var childs = React.cloneElement(this.props.content, pageArgs);

        let header; let menu; let burgerbtn; let
            menuStyle;
        const classes = ["app-layout"];

        const displayBurgerBtn = hasItemsInMenu(this.props.pageheader);

        if (this.props.rootwebsite && this.props.rootwebsite.detail) {
            classes.push("rootwebsite-" + this.props.rootwebsite.detail.id);
            if (!this.props.rootwebsite.hasCurrentEvent) {
                menuStyle = {
                    backgroundColor: "transparent",
                    color: this.props.visualConfiguration?.appHeader?.style?.color || ""
                };

                header = <AppHeader
                    visualConfiguration={this.props.visualConfiguration}
                    headerTemplate={this.props.pageheader}
                    datacontext={this.state.datacontext}
                    rootwebsite={this.props.rootwebsite}
                    headerSettings={(this.props.rootwebsite?.detail?.configuration as any)?.companion?.headerSettings}
                    hasBurgerBtn={displayBurgerBtn}
                />;

                burgerbtn = <div className="burger-menu" id="burger-menu" onClick={this.toggleMenu} style={menuStyle}>
                    <i className="inwink-menu" />
                </div>;
                menu = <AppMenu
                    {...pageArgs}
                    menuTemplate={this.props.pageheader}
                    isRootWebsite={true}
                    onHide={this.toggleMenu}
                    datacontext={this.state.datacontext}
                />;
            } else {
                classes.push("noheader");
            }
        }

        return <div className={classes.join(" ")}>

            <div className="app-content">
                {header}
                <AppShellWebsiteRoutes {...this.props} />
            </div>

            {displayBurgerBtn && burgerbtn}
            {menu}
        </div>;
    }
}
