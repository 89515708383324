import { getCookie } from '@inwink/utils/cookies';
import { States } from "@@services/services";

const INITIAL_STATE = {
    detail: null,
    websiteid: null,
    hasCurrentEvent: false,
    hasCurrentCommunity: false,
} as States.IRootWebsiteState;

export function rootWebsiteReducer(state = INITIAL_STATE, action = { type: '', payload: null }) {
    switch (action.type) {
        case "ROOTWEBSITE_REFRESH": {
            return Object.assign({}, state);
        }
        case "ROOTWEBSITE_HASCURRENTEVENT": {
            return Object.assign({}, state, {
                hasCurrentEvent: action.payload
            });
        }
        case "ROOTWEBSITE_HASCURRENTCOMMUNITY": {
            return Object.assign({}, state, {
                hasCurrentCommunity: action.payload
            });
        }
        case "ROOTWEBSITE_ALLOWTRACKING": {
            return Object.assign({}, state, {
                enableTracking: true
            });
        }

        case "ROOTWEBSITE_SET": {
            let enableTracking = false;
            const websiteconfig = action.payload.website && action.payload.website.configuration;
            if (websiteconfig?.companion?.cookiesWarning?.labels) {
                if (websiteconfig.companion.cookiesWarning.enabled) {
                    enableTracking = (typeof window !== "undefined" && !!getCookie("hasConsentFor" + action.payload.website.id));
                } else {
                    enableTracking = true;
                }
            } else {
                enableTracking = true;
            }

            return Object.assign({}, state, {
                requestManager: action.payload.requestManager,
                detail: action.payload.website,
                data: action.payload.data,
                isPreview: action.payload.isPreview,
                enableTracking: enableTracking,
                customerid: action.payload.website ? action.payload.website.customerId : null,
                websiteid: action.payload.website ? action.payload.website.id : null,
                tenantid: action.payload.website ? action.payload.website.authTenantId : null,
            });
        }

        case "ROOTWEBSITE_SETDATA": {
            return Object.assign({}, state, {
                data: action.payload,
                stamp: new Date()
            });
        }

        case "ROOTWEBSITE_DATACHANGED": {
            return Object.assign({}, state, {
                stamp: new Date()
            });
        }
        case "ROOTWEBSITE_BOOTSTRAPPREVIEW": {
            return {
                websiteid: action.payload.websiteid.toLowerCase(),
                customerid: action.payload.customerid.toLowerCase(),
                tenantid: action.payload.tenantid.toLowerCase(),
                isPreview: true
            };
        }
        default:
            return state;
    }
}

export const rootWebsiteActions = {

    bootstrapPreview(websiteid, customerid, tenantid) {
        return (dispatch) => {
            dispatch({
                type: 'ROOTWEBSITE_BOOTSTRAPPREVIEW',
                payload: { websiteid: websiteid, customerid: customerid, tenantid: tenantid } });
        };
    },

    hasCurrentEvent(hasevent:boolean) {
        return (dispatch) => {
            dispatch({ type: 'ROOTWEBSITE_HASCURRENTEVENT', payload: hasevent });
        };
    },

    hasCurrentCommunity(hascommunity:boolean) {
        return (dispatch) => {
            dispatch({ type: 'ROOTWEBSITE_HASCURRENTCOMMUNITY', payload: hascommunity });
        };
    },

    eventDataChanged() {
        return (dispatch) => {
            dispatch({ type: 'ROOTWEBSITE_DATACHANGED' });
        };
    },

};
