import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logging } from '@inwink/logging';
import { AnimatedSwitch } from '@inwink/animatedswitch';
import { Route } from 'react-router-dom';
import { Entities } from '@inwink/entities/entities';
import { getPageClass } from '@@components/page';
import { PageTransition } from '@@components/transitions';
import { AppShell } from '@@event/shell/appshell';
import { CommunityShell } from '@@community/shell/communityshell';
import { actions as i18nActions, IBestLanguage } from '@@services/i18nservice';
import { rootWebsiteActions } from '../services/rootwebsiteservice';
import { IAppShellWebsiteProps } from './rootwebsiteshell.props';

const logger = logging.getLogger("UI");

interface IAppShellWebsiteRoutesComponentProps extends IAppShellWebsiteProps {
    rootWebsiteActions? : typeof rootWebsiteActions;
    i18nActions? : typeof i18nActions;
}

class AppShellWebsiteRoutesComponent extends React.Component<IAppShellWebsiteRoutesComponentProps, any> {
    pages : any;

    rootPath : string;

    constructor(props: IAppShellWebsiteProps) {
        super(props);
        this.pages = {};
        this.pages.home = getPageClass("rootwebsitehome", "rootwebsitehomepage", { application: "rootwebsite" });
        this.pages.content = getPageClass((args) => {
            return args.match && args.match.params.contentpagetemplate;
        }, "rootwebsitecontentpage", { application: "rootwebsite", contenttype: "contentpage" });

        this.state = {
            routes: this.computeRoutes(props)
        };
    }

    computeRoutes(props: IAppShellWebsiteProps) {
        let rootpath = props.match.path;
        this.rootPath = props.match.url;
        if (rootpath[rootpath.length - 1] !== "/") {
            rootpath += "/";
        }

        return computeWebsiteRoutes(rootpath, props.rootwebsite && props.rootwebsite.detail, this);
    }

    render() {
        const classes = ["app-pages"];

        if (this.props.visualConfiguration) {
            if (this.props.visualConfiguration.global && this.props.visualConfiguration.global.appTheme) {
                classes.push(this.props.visualConfiguration.global.appTheme);
            }
        }

        const routes = this.state.routes.map((r) => {
            return <Route
                key={r.path || 'Une erreur est survenue'}
                exact={r.exact}
                path={r.path}
                render={r.render}
                component={r.component}
            />;
        });

        const style:any = {};
        if (this.props.visualConfiguration && this.props.visualConfiguration.global) {
            style.backgroundColor = this.props.visualConfiguration.global.backgroundColor;
            if (this.props.visualConfiguration.global.backgroundImage) {
                style.backgroundImage = "url(\"" + this.props.visualConfiguration.global.backgroundImage + "\")";
            }
        }

        return <AnimatedSwitch
            location={this.props.location}
            match={this.props.match}
            component="section"
            className={classes.join(" ")}
            style={style}
            transitionComponent={PageTransition}
        >
            {routes}
        </AnimatedSwitch>;
    }
}

function mapDispatchToProps(dispatch) {
    return {
        rootWebsiteActions: bindActionCreators(rootWebsiteActions, dispatch),
        i18nActions: bindActionCreators(i18nActions, dispatch),
    };
}

export const AppShellWebsiteRoutes: new (any) => React.Component<IAppShellWebsiteProps, any> = connect(
    null,
    mapDispatchToProps
)(AppShellWebsiteRoutesComponent as any) as any;

function registerRoutes(routes: any[], rootPath : string, page: AppShellWebsiteRoutesComponent, lngcode?:string) {
    const checkLanguage = (rprops) => {
        const renderprops = rprops;
        if (lngcode) {
            renderprops.match.params.lngcode = lngcode;
            if (page.props.location.pathname === renderprops.location.pathname) {
                if (page.props.i18n.currentLanguageCode !== lngcode) {
                    const lng = ((page.props.i18nActions.determineBestLanguage(lngcode)) as any) as IBestLanguage;
                    page.props.i18nActions.loadLanguage(lng.language, lng.dateDisplayLanguage, true);
                }
            }
        }

        if (page.props.rootwebsite.detail?.configuration?.global
            && page.props.i18n.supported !== page.props.rootwebsite.detail.configuration.global.supportedLanguages) {
            const conf = page.props.rootwebsite.detail.configuration.global;
            page.props.i18nActions.init(conf.defaultLanguage, conf.supportedLanguages);
        }
    };

    routes.push(
        {
            path: rootPath + 'content/:contentpagetemplate',
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                if (page.props.location.pathname === props.match.url && page.props.rootwebsite.hasCurrentCommunity) {
                    page.props.rootWebsiteActions.hasCurrentCommunity(false);
                    // page.props.eventActions.clearCurrentEvent();
                }
                if (page.props.location.pathname === props.match.url && page.props.rootwebsite.hasCurrentEvent) {
                    page.props.rootWebsiteActions.hasCurrentEvent(false);
                    // page.props.eventActions.clearCurrentEvent();
                }
                const renderprops = { ...props,
                    params: page.props.match && page.props.match.params,
                    webmasterConfiguration: page.props.webmasterConfiguration,
                    visualConfiguration: page.props.visualConfiguration,
                    visualstate: page.props.visualstate,
                    pagefooter: page.props.pagefooter
                };
                checkLanguage(renderprops);
                return React.createElement(page.pages.content, renderprops);
            }
        },
        {
            path: rootPath,
            exact: true,
            lngcode: lngcode,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                if (page.props.location.pathname === props.match.url && page.props.rootwebsite.hasCurrentCommunity) {
                    page.props.rootWebsiteActions.hasCurrentCommunity(false);
                    // page.props.eventActions.clearCurrentEvent();
                }
                if (page.props.location.pathname === props.match.url && page.props.rootwebsite.hasCurrentEvent) {
                    page.props.rootWebsiteActions.hasCurrentEvent(false);
                    // page.props.eventActions.clearCurrentEvent();
                }
                const renderprops = { ...props,
                    params: page.props.match && page.props.match.params,
                    webmasterConfiguration: page.props.webmasterConfiguration,
                    visualConfiguration: page.props.visualConfiguration,
                    visualstate: page.props.visualstate,
                    pagefooter: page.props.pagefooter
                };
                checkLanguage(renderprops);
                return React.createElement(page.pages.home, renderprops);
            }
        }
    );
}

export function computeWebsiteRoutes(rootpath: string, websitedetail: Entities.IRootWebsiteDetail,
    page?: AppShellWebsiteRoutesComponent) {
    const routes = [];

    if (websitedetail && websitedetail.configuration) {
        const cfg = websitedetail.configuration;
        if (cfg && cfg.global && cfg.global.supportedLanguages && cfg.global.supportedLanguages.length > 1) {
            cfg.global.supportedLanguages.forEach((l) => {
                registerRoutes(routes, rootpath + l + "/", page, l);
            });
        }
    }

    registerRoutes(routes, rootpath, page);

    routes.push(
        {
            path: rootpath + ":eventtinyurl",
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                if (page.props.location.pathname.indexOf(props.match.url) === 0 && !page.props.rootwebsite.hasCurrentEvent) {
                    page.props.rootWebsiteActions.hasCurrentEvent(true);
                }
                const renderprops = { ...props,
                    key: props.match.params.eventtinyurl,
                    targetEvent: props.match.params.eventtinyurl,
                    params: page.props.match && page.props.match.params,
                    webmasterConfiguration: page.props.webmasterConfiguration,
                    visualConfiguration: page.props.visualConfiguration,
                    visualstate: page.props.visualstate
                };

                if (page.props.rootwebsite.hasCurrentCommunity) {
                    return React.createElement(CommunityShell, renderprops);
                }

                if (page.props.rootwebsite.hasCurrentEvent) {
                    logger.debug("Render event page ", renderprops.targetEvent);
                    return React.createElement(AppShell, renderprops);
                }
                return <div />;
            }
        },
        {
            // eslint-disable-next-line react/display-name
            component: () => <div>error</div>
        }
    );

    return routes;
}
